import PropTypes from 'prop-types';
import InformationDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/InformationDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getContentWrapperStyles from '@activebrands/core-web/utils/style-functions/get-content-wrapper-styles';

const InformationModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    const contentWrapperStyles = getContentWrapperStyles();

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)', ...contentWrapperStyles })}>
            <InformationDataTransformerBlock {...rest} />
        </section>
    );
};

InformationModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default InformationModule;
